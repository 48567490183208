import React from 'react';
import moment from 'moment';

function HolidayChart({ holidays }) {


    return (
        <div className="holiday-chart-container">
          {/* <h2>Holiday Chart</h2> */}
          <table className="holiday-chart-table">
            <thead>
              <tr className="first-row">
                <th>Holiday</th>
                <th>Date</th>
                <th>Hebrew Date</th>
                {/* <th>Category</th>
                <th>Subcategory</th>
                <th>Yomtov</th>
                <th>Hebrew</th> */}
                {/* <th>Link</th> */}
              </tr>
            </thead>
            <tbody>
              {holidays.map((holiday, index) => (
                <tr key={index}>
                <td className="holiday-title-format">{holiday.title}</td>
                  {/* <td>{holiday.date}</td> */}
                  <td>{moment(holiday.date).format('dddd, MMMM D YYYY')}</td>
                  <td>{holiday.hdate}</td>
                  {/* <td>{holiday.category}</td>
                  <td>{holiday.subcat}</td>
                  <td>{holiday.yomtov ? 'Yes' : 'No'}</td>
                  <td>{holiday.hebrew}</td> */}
                  {/* <td>
                    <a href={holiday.link} target="_blank" rel="noopener noreferrer">
                      More information
                    </a>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
}

export default HolidayChart;