import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import HolidayData from './HolidayData';
import HolidayChart from './HolidayChart';
import AboutModal from './AboutModal';
import aboutText from './about.json';
import BuyMeACoffeeButton from './BuyMeACoffee';
import ReactGA from 'react-ga';


function App() {
  useEffect(() => {
    // Track the page view when the component mounts
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  


  const openAboutModal = () => {
    setShowAboutModal(true);
  };

  const closeAboutModal = () => {
    setShowAboutModal(false);
  };

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
  };
  
  return (
    <div className="App">
      <Header />
      <div className="content-container">
        <HolidayData />
        <div className="buttons-container">
          <button className="about-button" onClick={openAboutModal}>
            About
          </button>
          <button className="about-button" onClick={openContactModal}>
            Contact
          </button>
        </div>
      </div>
  
      {showAboutModal && (
        <div className="about-modal open">
          <div className="about-content">
            <h2>About The Site</h2>
            {/* About section content goes here */}
            {/* <p className="about-text">{aboutText.aboutTitle}</p>
            <p></p> */}
            <p className="about-text">{aboutText.aboutTextSimple1}</p>
            <p></p>
            {/* <p className="about-text">{aboutText.aboutText2}</p> */}
            <h3>Save Time!</h3>
            <p className="about-text">{aboutText.aboutWhyStart}</p>
            <p></p>
            <p className="bullet-list" dangerouslySetInnerHTML={{ __html: aboutText.aboutWhy }}></p>
          </div>
          <button className="close-button" onClick={closeAboutModal}>
            Close
          </button>
          <div className="hebcal-info">
            (calendar data sourced from{' '}
            <a href="https://www.hebcal.com/home/developer-apis" target="_blank" rel="noopener noreferrer">
              hebcal
            </a>{' '}
              API)
          </div>
        </div>
      )}
  
      {showContactModal && (
        <div className="contact-modal open">
          <div className="about-content">
            <h2>Contact Info</h2>
            {/* Contact section content goes here */}
            {/* <p>Contact information and links.</p> */}
            {/* <p>...</p> */}
            <p></p>
            <p className="contact-text">For any questions, or suggestions on improving the site, please email &nbsp;
            <a href="mailto:jewishholidaystoday@gmail.com">jewishholidaystoday@gmail.com</a>
            </p>
            <p></p>
          </div>
          <button className="close-button" onClick={closeContactModal}>
            Close
          </button>
          <div className="coffee-button-container">
          <h3 className="about-text">{aboutText.buyMeACoffeeText}</h3>
          <BuyMeACoffeeButton />
          </div>
        </div>
      )}
      
    </div>

  );
}  

export default App;
